<template>
    <v-card v-if="loading || count" elevation="0" :color="color" rounded class="d-flex pa-4 d-flex mt-4">
        <div v-if="loading" class="d-flex flex-grow-1 align-center justify-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
        <div v-else>
            <div :class="['subtitle-1 mb-2', textColor]">
                {{ this.type === 'unlock'? 'Unlock your responses!': "You'll lose some data" }}
            </div>
            <div class="d-flex">
                <v-icon class="pr-4" :color="this.type === 'unlock'?'success':'deep-orange'" large>
                    {{ this.type === 'unlock'? 'mdi-lock-open': 'mdi-lock' }}
                </v-icon>
                <div :class="['body-2', textColor]">
                    {{ text }}
                </div>
            </div>
        </div>
    </v-card>
</template>

<script>
import BillingApi from '../../api/BillingApi'
export default {
    name: 'ResponseSyncPreview',
    props: {
        planId: {
            default: null,
        }
    },
    data(){
        return {
            loading: false,
            type: null,
            count: 0,
        }
    },
    computed:{
        color(){
            if(this.type === 'unlock'){
                return 'success lighten-5'
            }

            if(this.type === 'lock'){
                return 'deepOrange lighten-5'
            }

            return 'grey lighten-5'
        },
        textColor(){
            if(this.type === 'unlock'){
                return 'success--text text-darken-5'
            }

            if(this.type === 'lock'){
                return 'deep-orange--text text-darken-5'
            }

            return 'grey text-darken-5'
        },
        text(){
            if(this.count){
                if(this.type === 'unlock'){
                    return `With this upgrade, you'll be unlocking ${this.count} responses 🎉`
                }else{
                    return `If you choose to switch to a lower plan, you won't be able to see ${this.count} responses that exceed the limit of your new plan. These responses will be removed within a week after you decide to downgrade.`
                }
            }
            return ''
        }
    },
    methods: {
        async previewResponsesSync(){
            try {
                this.loading=true
                const res = await  BillingApi.previewResponsesSync(this.planId)
                if(res && res.data){
                    this.count = res.data.count
                    this.type = res.data.type
                    this.$emit('responseSync', true)
                }
                this.loading=false
            } catch (error) {
                this.loading=false
            }
        }
    },
    created(){
        this.previewResponsesSync()
    }
}
</script>