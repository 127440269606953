<template>
    <div>
        <div class="text-h5">Usage</div>
        <v-row v-if="usage && typeof usage.number_of_submissions=== 'number'" no-gutters class="mt-5">
            <v-col md="8" lg="6" sm="12">
                <div class="d-flex mb-2">
                    <div class="text-subtitle-1">Responses</div>
                    <v-spacer></v-spacer>
                    <div class="d-flex">
                        <div class="text-subtitle-1">{{usage.number_of_submissions}}</div>
                        <div>/{{usage.submission_limit}} {{isActiveFreeTrial?`responses`:'this month'}}</div>
                    </div>
                </div>
                <v-progress-linear height="8" rounded :color="usageColor" :value="usageProgressValue"></v-progress-linear>
                <v-fade-transition>
                    <v-alert
                        v-if="usageAlertType"
                        class="mt-4"
                        :type="usageAlertType"
                    >
                        <div class="text-subtitle-1">
                            {{usageAlertTitle}}
                        </div>
                        <div>{{usageAlertMessage}}</div>
                        <router-link :to="{name: 'dashboard.upgrade'}">
                            <v-btn class="mt-4" elevation='0' outlined color="white">Upgrade</v-btn>
                        </router-link>
                    </v-alert>
                </v-fade-transition>
                <div v-if="!isActiveFreeTrial" class="mt-4 text-body-2">Your responses reset on {{usageResetDate}}.</div>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: "Usage",
    computed: {
        ...mapGetters({
            usage: 'billing/usage',
            user: 'auth/user'
        }),
        isActiveFreeTrial(){
           return  !!this.user && !!this.user.trial_ends_at && this.$date().isBefore(this.user.trial_ends_at)
        },

        trialDaysPeriod(){
            if(this.isActiveFreeTrial){
                return this.$date(this.user.trial_ends_at).diff(this.$date(), 'day')
            }
            return null
        },

        usageProgressValue(){
            try {
                return Math.round(this.usage.number_of_submissions/this.usage.submission_limit*100)
            } catch (error) {
                console.error('usageProgressValue error', error);
                return undefined
            }
        },

        usageAlertType(){
            if(this.usage.number_of_submissions > this.usage.submission_limit){
                return 'error'
            }
            if(this.usageProgressValue > 90){
                return 'warning'
            }
            return ''
        },

        usageColor(){
            switch (this.usageAlertType) {
                case 'error':
                   return 'error' 
                case 'warning':
                   return 'warning' 
                default:
                    return 'primary';
            }
        },

        usageAlertTitle(){
            if(this.usageAlertType==='error'){
                return "You've reached your monthly limit"
            }
            return "You're quickly approaching your monthly response limit"
        },

        usageAlertMessage(){
            if(this.usageAlertType==='error'){
                return "Additional responses will be saved, but you'll need to upgrade your plan within 7 days to ensure additional data is not deleted."
            }
            return `You're at ${this.usageProgressValue}% of your monthly response limit. If your plan is not upgraded, you'll lose responses above your ${this.usage.submission_limit} monthly limit within 7 days.`
        },

        usageResetDate(){
            if(this.usage && this.usage.current_period_end){
                return this.$date(this.usage.current_period_end).format('MMM D, YYYY')
            }
            return ''
        },
    }

}
</script>