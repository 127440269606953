<template>
    <empty-layout>
        <navigation-back-button></navigation-back-button>
        <v-sheet outlined rounded class="pa-10">
            <div class="d-flex">
                <div class="text-h4">Billing</div>
                <v-spacer></v-spacer>
                <router-link v-if="showUpgradeButton" :to="{name: 'dashboard.upgrade'}">
                    <v-btn large class="mr-4" elevation="0" color="primary">Upgrade</v-btn>
                </router-link>
            </div>
            <div v-if="showBillingDetails">
                <v-alert v-if="isActiveFreeTrial" icon="mdi-check" outlined class="my-6 pa-4" text type="success" v-html="billingDescription">
                </v-alert>
                <div v-else-if="billingDescription" class="mt-6 text-body-1" :key="billingDescription" v-html="billingDescription"></div>
                <div v-if="status" class="d-flex mt-4 align-center">
                    <div class="text-subtitle-1 grey--text text--darken-1">
                        Status
                    </div>

                    <v-chip small class="ml-4" :color="status.color">{{status.text}}</v-chip>
                </div>
                <div v-if="pricePlanName" class="d-flex mt-4">
                    <div class="text-subtitle-1 grey--text text--darken-1" style="min-width: 120px">
                        Selected Plan
                    </div>
                    <div v-if="pricePlanName">
                        {{pricePlanName}}
                    </div>
                </div>
                <div class="d-flex mt-4">
                    <div class="text-subtitle-1 grey--text text--darken-1" style="min-width: 120px">
                        {{pricePlanPeriod}}
                    </div>
                    <div>
                        {{planCost}}
                    </div>
                </div>
            </div>
            <v-expand-transition>
                <resume-subscription v-if="subscription && subscription.cancel_at_period_end"></resume-subscription>
            </v-expand-transition>
            <v-divider class="my-10"></v-divider>
            <usage></usage>
            <v-divider class="my-10"></v-divider>
            <credit-card-details></credit-card-details>
            <template v-if="showCancelSection">
                <v-divider class="my-10"></v-divider>
                <cancel-subscription></cancel-subscription>
            </template>
            <!-- TODO: Add what each plan includes -->
            <!-- <v-divider class="my-10"></v-divider>
            <div class="text-h5">Your Free plan includes</div>
            <div class="d-flex mt-5">
                <v-icon class="mr-4" color="primary">
                    mdi-check
                </v-icon>
                Feature 1
            </div> -->
        </v-sheet>
    </empty-layout>
</template>
<script>
import EmptyLayout from "../../layouts/EmptyLayout.vue"
import { mapActions, mapGetters } from 'vuex'
import CreditCardDetails from './CreditCardDetails.vue'
import Usage from './Usage.vue'
import CancelSubscription from './CancelSubscription.vue'
import ResumeSubscription from './ResumeSubscription.vue'
import NavigationBackButton from '../../layouts/NavigationBackButton.vue'
export default {
    components: { EmptyLayout, CreditCardDetails, Usage, CancelSubscription, ResumeSubscription, NavigationBackButton },
    name: 'Billing',
    methods: {
        ...mapActions({
            fetchSubscription: 'billing/fetchSubscription',
            loadPricePlans: 'billing/loadPricePlans',
            loadPaymentMethods: 'payment/loadPaymentMethods',
            fetchUsage: 'billing/fetchUsage',
        }),
    },
    computed: {
        ...mapGetters({
            subscription: 'billing/subscription',
            pricePlan: 'billing/pricePlan',
            user: 'auth/user'
        }),

        isActiveFreeTrial(){
           return  !!this.user && !!this.user.trial_ends_at && this.$date().isBefore(this.user.trial_ends_at)
        },

        interval(){
            if(this.subscription && this.subscription.plan){
                return this.subscription.plan.interval
            }
            return undefined
        },

        status(){
            if(this.subscription){

                switch (this.subscription.status) {
                    case 'active':
                        return {
                            color: 'success',
                            text: 'Active'
                        }
                    case 'incomplete':
                        return {
                            color: 'error',
                            text: 'Incomplete Payment'
                        }
                    case 'incomplete_expired':
                        return {
                            color: 'error',
                            text: 'Expired Incomplete Payment'
                        }
                    case 'canceled':
                        return {
                            color: 'grey',
                            text: 'Canceled'
                        }
                    case 'unpaid':
                        return {
                            color: 'error',
                            text: 'Unpaid'
                        }
                    case 'trailing': 
                        return {
                            color: 'primary',
                            text: 'On a trial'
                        }
                
                    default:
                        return null;
                }
            }
            return null
        },

        period(){
            if(this.subscription && this.subscription.plan){
                return this.interval === 'year' ? 'yearly' : 'monthly'
            }
            return ''
        },

        billingDescription(){
            if(this.isActiveFreeTrial){
                return `Your account is on a 15 day free trial of all Voiceform features. The trial will end on <b>${this.$date(this.user.trial_ends_at).format('MMM D, YYYY')}</b>, and will then be transitioned to your ${this.pricePlanName||'Free'} plan. Enjoy your trial!`
            }
            if(this.subscription && this.period){
                const date = this.$date(this.subscription.current_period_end*1000)
                return `Your account is billed ${this.period} and the next payment is due <b>${date.format('MMM D, YYYY')}</b>.`
            }
            
            return 'Your account is on basic free plan.'
        },
        pricePlanName(){
            if(this.pricePlan && this.subscription){
                if(this.subscription.plan){
                    return `${this.pricePlan.name} (${this.period})`
                }
                return 'Free'
            }
            return ''
        },
        pricePlanPeriod(){
            if(this.subscription && this.subscription.plan){
                return this.interval === 'year' ? 'Annual Cost' : 'Monthly Cost'
            }
            return 'Cost'
        },
        planCost(){
            if(this.subscription && this.subscription.plan){
                
                const priceValue = this.subscription.plan.amount/100
                const price = `USD ${new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                }).format(Number(priceValue))}`


                return this.interval? `${price}/${this.interval}`: price
            }
            return 'USD 0.00'
        },

        planName(){
            const interval = this.interval === 'year'? 'yearly' :'monthly'
            return `${this.currentPlan.name} (${interval})`
        },

        showUpgradeButton(){
            // TODO do not show upgrade during grace period
            if(this.subscription && this.subscription.cancel_at_period_end){
                return false
            }
            return true
        },

        showCancelSection(){
           return this.subscription && Object.keys(this.subscription).length !== 0 && !this.subscription.cancel_at_period_end
        },
        
        showBillingDetails(){
            if(this.pricePlan && !this.subscription){
                return false
            }
            return true
        }

    },
    mounted(){
        this.fetchSubscription()
        this.loadPricePlans()
        this.loadPaymentMethods()
        this.fetchUsage()
    }
}
</script>