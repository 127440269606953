<template>
    <div>
        <div class="d-flex mb-5">
            <div class="text-h5">Credit card details</div>
            <v-spacer></v-spacer>
            <v-btn v-if="!showCardInputs && defaultPaymentMethod" outlined @click="showCardInput" color="secondary">Change</v-btn>
        </div>
        <v-row v-if="!showCardInputs" no-gutters class="mt-5">
            <v-col v-if="defaultPaymentMethod" md="8" lg="6" sm="12">
                <v-row class="mt-4" no-gutters>
                    <v-col class="grey--text text--darken-1">Card Number</v-col>
                    <v-col>**** **** **** {{defaultPaymentMethod.last_four}} {{defaultPaymentMethod.brand}}</v-col>
                </v-row>
                <v-row class="mt-4" no-gutters>
                    <v-col class="grey--text text--darken-1">Expiry</v-col>
                    <v-col>{{defaultPaymentMethod.exp_month}}/{{defaultPaymentMethod.exp_year}}</v-col>
                </v-row>
            </v-col>
            <v-col v-else md="8" lg="6" sm="12">
                <div>You haven't added any information yet</div>
                <v-btn class="mt-4" @click="showCardInput" outlined color="primary">Add credit card details</v-btn>
            </v-col>
        </v-row>
        <v-row v-else no-gutters class="mt-5">
            <div v-if="elementsOptions.clientSecret" style="width: 100%;">
                <stripe-element-payment 
                    :pk="publishableKey"
                    ref="paymentRef"
                    :elements-options="elementsOptions"
                    :confirmParams="confirmParams"
                    @loading="handleLoading"
                    @element-ready="handleReady"
                >
                </stripe-element-payment>
                <div v-if="loadingElement" class="pa-4 primary lighten-5">
                    <v-progress-linear   indeterminate color="primary"></v-progress-linear>
                </div>
                <v-btn elevation="0" :loading="submitting" :disabled="submitting" class="mt-4" color="primary" @click="submitPaymentMethod">Save Billing Info</v-btn>
                <v-btn elevation="0" :disabled="submitting" class="ml-4 mt-4" @click="hideCardInput">Cancel</v-btn>
            </div>
        </v-row>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { StripeElementPayment } from '@vue-stripe/vue-stripe';
import BillingApi from '../../api/BillingApi';
export default {
    name: 'CreditCardDetails',
    components: {
        StripeElementPayment
    },
    data() {
        return {
            showCardInputs: false,
            loadingElement: false,
            publishableKey: process.env.VUE_APP_STRIPE_KEY,
            elementsOptions: {
                appearance: {
                    theme: 'minimal',
                        variables: {
                            colorPrimary: '#0066FF',
                            colorBackground: '#ffffff',
                            colorText: '#212121',
                            colorDanger: '#F44336',
                            fontFamily: 'Poppins:wght@300;400;500;600;700&display=swap',
                            spacingUnit: '4px',
                            borderRadius: '4px',
                    }
                }, // appearance options
                
            },
            submitting: false,
            confirmParams: {
                return_url: window.location.href
            }
        };
    },
    methods: {
        ...mapActions({
            loadPaymentMethods: "payment/loadPaymentMethods",
            updatePaymentMethod: "payment/updatePaymentMethod",
        }),
        ...mapMutations(['showSnackBar']),
        showCardInput(){
            this.showCardInputs = true
            this.loadingElement = true
            this.$emit('changeCardInfo', true)
        },
        hideCardInput(){
            this.showCardInputs = false
            this.$emit('changeCardInfo', false)
        },
        async generatePaymentIntent() {
            try {
                this.loadingElement = true;
                const paymentIntent  = await BillingApi.loadIntentToken();
                this.elementsOptions = {...this.elementsOptions, clientSecret: paymentIntent.client_secret}
                this.loadingElement = false;
            } catch (error) {
                this.loadingElement = false;
                console.error('generatePaymentIntent', error);
            }
        },
        async submitPaymentMethod(){
            this.submitting = true
            const setupRes = await this.$refs.paymentRef.stripe.confirmSetup({
                //`Elements` instance that was used to create the Payment Element
                elements: this.$refs.paymentRef.elements,
                redirect: 'if_required',
                confirmParams: {
                    return_url: window.location.href
                }
            });


            if(setupRes.error){
                this.submitting = false
                this.showSnackBar({
                    text: setupRes.error.message || 'Wrong credit card information.', 
                    color: 'error', 
                    timeout: 2000
                })
            }else{
                const paymentMethodAdded = await BillingApi.addPaymentMethod(setupRes.setupIntent.payment_method)
                await this.loadPaymentMethods();
                await this.updatePaymentMethod(paymentMethodAdded);
                this.submitting = false
                this.showCardInputs = false
                this.$emit('changeCardInfo', false)
                this.$gtag.event('update_payment_method');
            }
        },
        handleReady(){
            this.loadingElement = false
        },
        handleLoading(){
            this.loadingElement = true
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            defaultPaymentMethod: 'payment/defaultPaymentMethod'
        }),
    },
    mounted () {
        this.loadPaymentMethods();
        this.generatePaymentIntent();
    },
}
</script>