<template>
    <div>
        <div class="text-h5 mb-8">Cancel Subscription</div>
        <div class="text-body-1 mb-5">You may cancel your subscription at any time. Once your subscription has been cancelled, you will have the option to resume the subscription until the end of your current billing cycle.</div>
        
        <v-dialog v-model="dialog" width="auto">
            <template v-slot:activator="{ on, attrs }">
                <v-btn outlined v-bind="attrs"
          v-on="on" color="error" :loading="loading" :disabled="loading">
                    Cancel Subscription
                </v-btn>
            </template>
            <v-card width="500" class="pa-6">
                <div class="text-h6">Are you certain you want to end your subscription?</div>
                <responses-sync-preview @responseSync="responsesSyncReady=true" :planId="null"></responses-sync-preview>
                <div v-if="responsesSyncReady" class="d-flex mt-6">
                    <v-btn @click="handleChangeMindClick" color="primary" elevation="0">
                        No, I changed my mind
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn  color="error" text @click="handleCancelClick" :loading="loading" :disabled="loading">
                        Yes, I want to cancel
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ResponsesSyncPreview from './ResponsesSyncPreview.vue'
export default {
    components: { ResponsesSyncPreview },
    name: 'CancelSubscription',
    data(){
        return{
            loading: false,
            showCancelDialog: false,
            dialog:false,
            responsesSyncReady: false
        }
    },
    computed:{
        ...mapGetters({
            subscription: 'billing/subscription'
        }),
        hideSection(){
            return !this.subscription || this.subscription.cancel_at_period_end
        }
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        ...mapActions({
            cancelSubscription: 'billing/cancelSubscription'
        }),
        handleChangeMindClick(){
            this.dialog = false
            window.heap.track('changed-mind-cancellation');
        },
        async handleCancelClick(){
            try {
                this.dialog= false
                this.loading = true
                const res = await this.cancelSubscription()
                if(res.data){
                    this.showSnackBar({
                        color: "success",
                        timeout: 3000,
                        text: "The current subscription has been canceled successfully",
                    });
                }
                this.loading = false
            } catch (error) {
                this.loading=false
                console.error('cancelSubscription', error);
            }
           
        },
    },
}
</script>