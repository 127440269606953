<template>
    <div class="mt-6">
        <v-card elevation="0" class="pa-4" color="primary lighten-5">
            <div class="text-body-1 mb-4">Your current subscription will be canceled on <b>{{cancellationDate}}</b></div>
            <div class="text-subtitle-1 mb-4">We already miss you <span class="text-h6 mt-2">&#128546;</span></div>
            <div class="text-body-1">Having second thoughts about cancelling your subscription? You can instantly reactivate your subscription at any time until the end of your current billing cycle.</div>
            <v-btn @click="handleResumeClick" :loading="loading" :disabled="loading" elevation="0" class="mt-4" large color="primary">Resume Subscription</v-btn>
        </v-card>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    name: 'ResumeSubscription',
    data(){
        return {
            loading: false
        }
    },
    computed: {
        ...mapGetters({
            subscription: 'billing/subscription'
        }),

        cancellationDate(){
            return this.$date(this.subscription.cancel_at*1000).format('MMM D, YYYY')
        }
    },
    methods: {
        ...mapActions({
            resumeSubscription: `billing/resumeSubscription`
        }),
        async handleResumeClick(){
            try {
                this.loading = true
                await this.resumeSubscription()
                this.loading = false
            } catch (error) {
                this.loading = false
                console.error('handleResumeClick', error);
            }
        }
    }
}
</script>